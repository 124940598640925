import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  palette: {
    primary: {
      main: "#c3161c",
      dark: "#7e0e12",
    },
    secondary: {
      main: "#58595b",
    },
  },
});

export default theme;
